/**
 * Updated the import to use dynamic import instead of a static one.
 * This ensures that the fetch method from Datadog is not overwritten by isomorphic-unfetch,
 * allowing Datadog to properly capture XHR calls and add traces for allowed domains.
 */
let fetch
;(async () => {
  const fetchModule = await import('isomorphic-unfetch')
  fetch = fetchModule.default
})()

import getConfig from 'next/config'
import nookies from 'nookies'
import { AUTH_TOKEN_KEY } from './../components/AccountProvider'
import isEmpty from 'lodash/isEmpty'
import queryString from 'query-string'
import _get from 'lodash/get'

const {
  publicRuntimeConfig: { API_URL, INTERNAL_API_URL, PUBLIC_API_URL, FILE_URL },
} = getConfig()

export { API_URL, PUBLIC_API_URL }

// Please don't use this in your code.
export const __INTERNAL_ERROR_HANDLER_DO_NOT_USE__ = (() => {
  const queue = []
  return {
    subscribe(callback) {
      queue.push(callback)
    },
    publish(code, onAuthErrorLogout) {
      queue.forEach(callback => callback(code, onAuthErrorLogout))
    },
    empty() {
      queue.length = 0
    },
  }
})()

export class InvalidHTTPError extends Error {
  constructor({ name, message, code, responseStatus }) {
    super()
    this.name = name
    this.message = message
    this.code = code
    this.responseStatus = responseStatus
  }
}

//avoid unexpected end of JSON input error
const parseJSON = response => {
  return response.text().then(function (text) {
    try {
      return text && !isEmpty(text) ? JSON.parse(text) : {}
    } catch (error) {
      //in most cases, this error is caused by response is not following JSON format
      return {}
    }
  })
}

const fetcher =
  (init, errorHandler, nodeURL = false) =>
  async (input, params = {}, options = {}) => {
    const isServer = process.browser === false
    const URL = isServer ? INTERNAL_API_URL : API_URL
    const [baseUrl, queryStringData] = input.split('?')

    const parsedQueryParams = queryString.parse(queryStringData)

    const newInput = input.includes('?')
      ? `${encodeURI(baseUrl)}?${queryString.stringify(parsedQueryParams)}`
      : encodeURI(input)

    let url = input && input.includes('http') ? newInput : `${URL}/${newInput}`

    // If we are accessing a URL configured in NodeJs we don't need to append the frontend gateway URL.
    if (nodeURL) {
      url = input
    }

    let response
    // eslint-disable-next-line
    try {
      response = await fetch(url, {
        ...init,
        ...params,
      })
    } catch (error) {
      throw error
    }

    if (!response.ok) {
      // Using the error messages from ZS because they are more
      // descriptive.
      //catching error status equals or more than 500
      const error = await parseJSON(response)
      const { publish } = errorHandler
      if (typeof publish === 'function') {
        publish(error.code, options.onAuthErrorLogout)
      }
      throw new InvalidHTTPError(error)
    }
    return parseJSON(response)
  }

const langHeader = {
  'Accept-Language': 'en',
}

// TODO: Feel free to move th`is` to a React Context API if you know how
// to share the same method with `getInitialProps` of Next.js `pages` and `App`.
// -
// Reasons to use this method instead of plain `fetch`:
// - Treats any HTTP status codes outside of 200-299 as error by default.
// - Prepends the API_URL for you.
// - You still retain full flexibility about how to handle the response/error.
// - When your request hits 401, the app will clean up automatically and redirect to login page.
export const get = fetcher(
  { method: 'get', headers: langHeader },
  __INTERNAL_ERROR_HANDLER_DO_NOT_USE__
)
export const post = fetcher(
  { method: 'post', headers: langHeader },
  __INTERNAL_ERROR_HANDLER_DO_NOT_USE__
)
export const put = fetcher(
  { method: 'put', headers: langHeader },
  __INTERNAL_ERROR_HANDLER_DO_NOT_USE__
)
export const del = fetcher(
  { method: 'delete', headers: langHeader },
  __INTERNAL_ERROR_HANDLER_DO_NOT_USE__
)

export const patch = fetcher(
  { method: 'PATCH', headers: langHeader },
  __INTERNAL_ERROR_HANDLER_DO_NOT_USE__
)

// This function is for GET call where we can ignore API prefix
export const getNP = fetcher(
  { method: 'get', headers: langHeader },
  __INTERNAL_ERROR_HANDLER_DO_NOT_USE__,
  true
)

export const headersWithAuthToken = authToken => {
  return {
    ...(authToken && { Authorization: `Bearer ${authToken}` }),
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...langHeader,
  }
}

// Simplified API call for client pdf file call
export const headersForPdf = context => {
  let authToken
  if (typeof window !== 'undefined') {
    authToken = nookies.get(context || {})[AUTH_TOKEN_KEY]
  }
  return {
    ...(authToken && { Authorization: `Bearer ${authToken}` }),
    Accept: 'application/pdf',
    'Content-Type': 'application/pdf',
    ...langHeader,
  }
}

// Use this like `await get('organization', { headers: headers(ctx) })`
// to include headers with Bearer Token (or anything that is necessary in the future
// to be included in order to get response from backend).

// NOTE:
// If you are using this in an event handler that only executes on the client-side
// like a click handler, the argument `context` is optional.
export const headers = (
  context,
  isRequiredAuth,
  isAuthTokenManuallyPassed,
  authValue
) => {
  let authToken
  if (typeof window !== 'undefined') {
    authToken = nookies.get(context || {})[AUTH_TOKEN_KEY]
  } else {
    if (isAuthTokenManuallyPassed) {
      authToken = authValue
    } else {
      authToken = _get(context, 'req.session.passport.user.accessToken')
    }
  }

  //before sending request, we need to verify the auth token in cookies
  //to prevent useless requests without auth token
  if (isRequiredAuth && !authToken) {
    throw { code: 401 }
  }

  return headersWithAuthToken(authToken)
}

// I am assuming that all backend errors are returned with similar
// formats.
export const parseAPIError = error => {
  // Since there are two format for message one with single colon and another with two colon before message
  const parsed =
    error.message.replace(/^[^:]*:(?:[^:]*:\s*)?\s*/, '') || error.message

  return {
    // In most cases, this is the message that is meant for UI.
    message: parsed,
    // Include other error details for tracking if needed.
  }
}

const fileFetcher = async (endpoint, params) => {
  return fetch(`${FILE_URL}/${endpoint}`, {
    ...params,
  })
    .then(data => {
      if (!data.ok) {
        throw Error('Unable to fetch file')
      }
      return data.arrayBuffer()
    })
    .catch(error => {
      if (error instanceof Error) {
        throw error
      }

      return error.json().then(errorJson => {
        throw Error(errorJson.message)
      })
    })
}

// Method for fetching files for loggedIn users on client side only
export const getFile = endpoint => {
  return fileFetcher(endpoint, {
    method: 'get',
    headers: headersForPdf(),
  })
}
